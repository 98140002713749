import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NotificationServices } from '../../appauth/notification/notificatio.services';
import { Impresora } from '../../global/global.model';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { ListaComercialesServices } from '../../global/glogal.services';
import { DataSharingService } from '../../global/dataService.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'green';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  showRtl = false;
  userdata: any;
  grupos = [];
  comerciales = [];
  locales = [];
  resultDevices:any;
  public innerWidth: any;
  public config: PerfectScrollbarConfigInterface = {};

  insertar: boolean = false;
  dataImpresora = {} as Impresora;
  Persona = {} as any;
  actulizar: boolean = false;
  ulrsimp = 'https://';
  infoMetraje: any;
  rutaVenta: string = "#/pedidos-ventas/crear-venta-index";

  constructor(public router: Router,
    private Noti: NotificationServices,
    public servicioAdministradores: ListaComercialesServices,
    private DataSharing: DataSharingService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private setting: ListaComercialesServices,
    public localdata: localStorageServices,) {
    this.DataSharing.SharingData.subscribe((res: any) => {
      this.infoMetraje = res;
    });
    this.epicFunction(); 
  }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    if(this.userdata.empresa){
      this.empresaVenta(this.userdata.empresa);
    }
    let dataSend = { 'user_id': this.userdata.datos.id } as any;
    if (this.userdata.grupo.group_id == 1) {
      this.servicioAdministradores.viewAdministradoresPorUsuario(dataSend).subscribe((data: any) => {
        if (data.administradores) {
          this.comerciales = data.administradores;
          if (this.comerciales.length == 1) {
            this.seleccionarEmpresa(this.comerciales[0]);
            this.empresaVenta(this.comerciales[0]);
          }
          this.comerciales.forEach((element, index) => {
            this.comerciales[index].select = false;
            if (this.userdata.empresa != undefined) {
              if (element.comerciale_id == this.userdata.empresa.comerciale_id) {
                this.comerciales[index].select = true;
              }
            }
          });
        }
      });
    } else if (this.userdata.grupo.group_id == 4) {
      this.servicioAdministradores.viewContadoresPorUsuario(dataSend).subscribe((data: any) => {

        if (data.administradores) {
          this.comerciales = data.administradores;
          if (this.comerciales.length == 1) {
            this.seleccionarEmpresa(this.comerciales[0]);
          }
          this.comerciales.forEach((element, index) => {
            this.comerciales[index].select = false;
            if (this.userdata.empresa != undefined) {
              if (element.comerciale_id == this.userdata.empresa.comerciale_id) {
                this.comerciales[index].select = true;
              }
            }
          });
        }
      });
    } else if (this.userdata.grupo.group_id == 5) {
      this.servicioAdministradores.viewLocalesPorUsuario(dataSend).subscribe((data: any) => {
        if (data.encargados) {
          this.locales = data.encargados;
          if (data.encargados.length == 1) {
            this.seleccionarEmpresa(data.encargados[0].locale);
            this.empresaVenta(data.encargados[0].locale);
          }
          this.locales.forEach((element, index) => {
            this.locales[index].select = false;
            if (this.userdata.empresa != undefined) {
              if (element.locale_id == this.userdata.empresa.id) {
                this.locales[index].select = true;
              }
            }
          });
        }
      })


    } else {
      if (this.userdata.grupo.group_id != 2) {
        this.servicioAdministradores.viewAdministradoresPorUsuario(dataSend).subscribe((data: any) => {
          if (data.administradores) {
            this.comerciales = data.administradores;
            if (this.comerciales.length == 1) {
              this.seleccionarEmpresa(this.comerciales[0]);
            }
            this.comerciales.forEach((element, index) => {
              this.comerciales[index].select = false;
              if (this.userdata.empresa != undefined) {
                if (element.comerciale_id == this.userdata.empresa.comerciale_id) {
                  this.comerciales[index].select = true;
                }
              }
            });
          }
        });
      }
    }

    this.grupos = this.userdata.datos.group_users;
    this.grupos.forEach((element, index) => {
      this.grupos[index].select = false;
      if (this.userdata.grupo != undefined) {
        if (element.group_id == this.userdata.grupo.group_id) {
          this.grupos[index].select = true;
        }
      }
    });
    this.listaImpresora();
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.handleLayout();
    this.servicioAdministradores.viewInformacionMetraje().subscribe((data: any) => {
      this.DataSharing.SharingData.next(data.trazos);
    });
  }

  empresaVenta(data:any){
    if(data!=undefined){
      this.setting.viewConfigGeneral(data.comerciale.id).subscribe((info: any) => {
        if (info.settingapp.rutaventas) {
          if(this.resultDevices.desktop && !this.resultDevices.table && !this.resultDevices.movil){
            this.rutaVenta = info.settingapp.rutaventas;
          }else if(!this.resultDevices.desktop && !this.resultDevices.table && this.resultDevices.movil){
            this.rutaVenta = info.settingapp.rutaventas+'-movil';
          }
          
          if(this.userdata.grupo.group_id == 5){
            this.setting.buscarSettinglocalesVentas(data.id).subscribe((local:any)=>{
              if (local.settinlocale && local.settinlocale.rutaventas) {
                this.rutaVenta=local.settinlocale.rutaventas;
              }
            })
          }
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }

  listaImpresora() {
    if (this.userdata.datos != undefined) {
      let dataSend = { 'group_id': this.userdata.grupo.group.id, 'user_id': this.userdata.grupo.user_id } as any;
      this.servicioAdministradores.viewAdministradoresImpresoras(dataSend).subscribe((datadmin: any) => {
        if (datadmin.impresora != undefined) {
          this.dataImpresora = datadmin.impresora;
          this.ulrsimp = 'https://' + this.dataImpresora.ip + ':3333'; ``
          this.insertar = true;
        } else {
          this.insertar = false;
        }
      });
    }
  }

  seleccionar(data: any) {
    this.userdata.grupo = data;
    this.localdata.setAuthorizationData(this.userdata);
    this.grupos = this.userdata.datos.group_users;
    this.grupos.forEach((element, index) => {
      this.grupos[index].select = false;
      if (this.userdata.grupo != undefined) {
        if (element.group_id == this.userdata.grupo.group_id) {
          this.grupos[index].select = true;
        }
      }
    });
    this.router.navigate(['home']);
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  guardar(data: any) {
    this.dataImpresora = data.value;
    this.dataImpresora.user_id = this.userdata.datos.id;
    this.dataImpresora.estado = 1;
    this.servicioAdministradores.saveImpresoras(this.dataImpresora).subscribe((dataImpr: any) => {
      if (dataImpr.estado) {
        this.Noti.setNotification(dataImpr).then((datas) => {
          if (datas) {
            this.listaImpresora();
            this.insertar = true;
          }
        });
      } else {
        this.Noti.setNotification(dataImpr);
      }
    });

  }

  actulizarInfo() {
    this.servicioAdministradores.updateImpresoras(this.dataImpresora).subscribe((dataImpr: any) => {
      if (dataImpr.estado) {
        this.Noti.setNotification(dataImpr).then((datas) => {
          if (datas) {
            this.actulizar = false;
            this.listaImpresora();
          }
        });
      } else {
        this.Noti.setNotification(dataImpr);
      }
    });
  }

  habiltar() {
    this.actulizar = true;
  }

  actulizarCancel() {
    this.actulizar = false;
  }

  probar() {
    let sendCod = { data: { "empresa": "CODEKUNA" }, ip: this.dataImpresora.ip } as any;
    this.servicioAdministradores.printerPueba(sendCod).subscribe();
  }

  irTrazos() {
    this.router.navigateByUrl('/textiles-config/trazo-rollos', { relativeTo: this.route.parent, skipLocationChange: true });
  }

  decimales() {
    this.router.navigate(['home']);
    setInterval(() => {
      window.location.reload();
    }, 1000);
  }

  seleccionarEmpresa(data: any) {
    this.userdata.empresa = data;
    this.localdata.setAuthorizationData(this.userdata);
    this.router.navigate(['home']);
  }

  seleccionarLocale(data: any) {
    this.userdata.empresa = data.locale;
    this.localdata.setAuthorizationData(this.userdata);
    this.router.navigate(['home']);
  }

  epicFunction() {
    let deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    this.resultDevices={info:deviceInfo,movil:isMobile,table:isTablet,desktop:isDesktopDevice} as any;
  }

}
