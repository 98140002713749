import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../global/global.model';
import {Arqueodecajas} from './arqueodecajas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class ArqueodecajasServices {

	constructor(private http: HttpClient) { }

	getDataArqueodecajas():Observable<Arqueodecajas[]>{
		return this.http.get<Arqueodecajas[]>(Globals.BASE_URL_API_REST+'arqueodecajas.json');
	}

	viewUsuarioPorLocales(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'usarios_por_locales.json',data);
	}

	viewDiarioCajas(id:number,idemp:number,fecha:any){
		return this.http.get(Globals.BASE_URL_API_REST+'lista_diaria_caja/'+id+'/'+idemp+'/'+fecha+'.json');
	}

	viewDiarioCajasLista(id:number,idemp:number){
		return this.http.get(Globals.BASE_URL_API_REST+'lista_diaria_caja_filtrar/'+id+'/'+idemp+'.json');
	}

	saveArqueodecajas(data:Arqueodecajas){
		return this.http.post(Globals.BASE_URL_API_REST+'arqueodecajas.json',data);
	}

	deleteArqueodecajas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'arqueodecajas/'+id+'.json');
	}

	updateArqueodecajas(data:Arqueodecajas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'arqueodecajas/'+data.id+'.json',data);
	}

	viewArqueodecajas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'arqueodecajas/'+id+'.json');
	}
}