import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { localStorageServices } from '../auth/localStorageServices';
import { Router } from "@angular/router";
import { ListaComercialesServices } from '../../global/glogal.services';
import { from } from 'rxjs/internal/observable/from';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    userdata: any;
    token: string;

    constructor(private localdata: localStorageServices,
        private setting: ListaComercialesServices,
        private router: Router) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let tokendata = null;
        if (JSON.stringify(this.localdata.getValueFromLocalStorage()) != 'null' && JSON.stringify(this.localdata.getValueFromLocalStorage()) != '{}') {
            tokendata = this.localdata.getValueFromLocalStorage().datos.token;
        }
        let tokendatacodekuna = null;
        if (JSON.stringify(this.localdata.getValueClienteCodeKuna()) != 'null' && JSON.stringify(this.localdata.getValueClienteCodeKuna()) != '{}') {
            tokendatacodekuna = this.localdata.getValueClienteCodeKuna();
        }
        if (req.headers.get('Authorization') == 'SmartRoad 1803994621') {

            return next.handle(req.clone({ setHeaders: { Authorization: 'SmartRoad ' + tokendatacodekuna } }));

        } else {
            let authHeader = 'SmartRoad ' + tokendata;
            const authReq = req.clone({ setHeaders: { Authorization: authHeader } });
            return next.handle(authReq);
        }

    }
}