import { Component, OnInit,ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { Users } from '../../user/users.model';
import { CustomValidators } from 'ng2-validation';
import {UserServices} from '../../user/users.services';
import {NotificationServices} from '../../../notification/notificatio.services';
import {GroupsServices} from '../../../group-pages/groups/groups.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-administrador-user',
    templateUrl:'./administrador-component.html',
    encapsulation: ViewEncapsulation.None
  })
  export class UserAdministradorModalComponent implements OnInit {
    @Input() public comercial;
    public formAddUser: FormGroup;
    breakpoint: number;
    ratio = '4:1';
    groupdata:any;
    constructor(
                private fb: FormBuilder,
                private servicesUser:UserServices,
                private activeModal:NgbActiveModal,
                private servicesGroup:GroupsServices,
                private Noti:NotificationServices,) {}
    
    ngOnInit(){
      this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
      this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
      this.formAddUser = this.fb.group({
        username: [null, Validators.compose([Validators.required, CustomValidators.email])],
        nicname: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
        identificador: [null,null],
        group_id: [null, Validators.compose([Validators.required])],
        comerciale_id: [this.comercial.id, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])],
        estado: [1,null],
      });
      this.servicesGroup.getDataGroupsFiltro().subscribe((data:any)=>{
        this.groupdata=data.groups;
      });
    }

    saveUser(){
      
      let userDataModel:Users=this.formAddUser.value;

      
     
      this.servicesUser.saveUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.activeModal.close(1);
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }

    onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
      this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
      
    }

  }

  