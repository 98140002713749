import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { customValidatorMondogo } from './custom-validator/customValidator.services';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    DragulaModule,
    QuillModule
  ],
  declarations: [
  ],
  providers:[
    customValidatorMondogo
  ]
})

export class AppImpuestosModule {}
