import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthLoginGuard } from './appauth/auth/auth.login.guard';
import {AuthGuard} from './appauth/auth/auth.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', 
        redirectTo: '/home', 
        pathMatch: 'full', },
      {
        path: 'home',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule),
        canActivate: [AuthGuard]
      }
      ,{
        path: 'grupos',
        loadChildren: () => import('./appauth/group-pages/groups.module').then(m => m.GroupsModule),
        canActivate: [AuthGuard],  
        data: { roles: ['Gerente'] }     
      },{
        path: 'usuarios',
        loadChildren: () => import('./appauth/user-pages/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
      },{
        path: 'configuracion',
        loadChildren: () => import('./appasclepio/configuracion/configuracion.module').then(m => m.ConfiguracionModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Administrador'] }
      },
      {
        path: 'ubicacion',
        loadChildren: () => import('./appasclepio/ubicacion/ubicacion.module').then(m => m.UbicacionModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      },
      {
        path: 'pedidos',
        loadChildren: () => import('./appinsumos/config-pedidos/config-pedidos.module').then(m => m.ConfigPedidosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Farmacia'] }
      },{
        path: 'empresa',
        loadChildren: () => import('./appinsumos/config/config.module').then(m => m.ConfigModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Farmacia','Administrador'] }
      },{
        path: 'realizar-pedido',
        loadChildren: () => import('./appinsumos/config-realizar-pedidos/config-realizar-pedidos.module').then(m => m.ConfigRealizarPedidosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Farmacia'] }
      },{
        path: 'bodegas',
        loadChildren: () => import('./appinsumos/config-bodegas/config-bodegas.module').then(m => m.ConfigBodegasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Farmacia'] }
      },{
        path: 'pedidos-ventas',
        loadChildren: () => import('./appinsumos/config-pedidos-ventas/config-pedidos-ventas.module').then(m => m.ConfigPedidosVentasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Vendedor'] }
      },{
        path: 'confirmar-ventas',
        loadChildren: () => import('./appinsumos/config-confirmar-ventas/config-confirmar-ventas.module').then(m => m.ConfigConfirmarVentasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente','Contabilidad','Vendedor'] }
      },{
        path: 'empresa-config',
        loadChildren: () => import('./appempresa/config/config.module').then(m => m.ConfigEmpresaModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador'] }
      },{
        path: 'facturero-config',
        loadChildren: () => import('./appfactureros/administraciones/facadministracion.module').then(m => m.FacturadosFacurasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Administrador','Contabilidad','Vendedor'] }
      },{
        path: 'textiles-config',
        loadChildren: () => import('./apptextiles/disenioprendas/disenioprendas.module').then(m => m.DisenioprendasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente'] }
      },{
        path: 'disenio-cliente-config',
        loadChildren: () => import('./appcliente/disenios/clientedisenos.module').then(m => m.ClienteDiseniosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Cliente'] }
      }, {
        path: 'config-empresa',
        loadChildren: () => import('./appconfig/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador','Gerente'] }
      }, {
        path: 'facturas-consultas',
        loadChildren: () => import('./appfacturas/busquedas/busquedas.facturas.module').then(m => m.AdmisionesFacturaInformesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Administrador','Contabilidad'] }
      }, {
        path: 'plantillas',
        loadChildren: () => import('./appplantillas/facturas-plantillas/facturas-plantillas.module').then(m => m.FacturasPlantillastosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Administrador', 'Contabilidad'] }
      }, {
        path: 'config-impuesto-conta',
        loadChildren: () => import('./appimpuestos/contabilidades/contabilidades.module').then(m => m.ContabilidadesModule),
        canActivate: [AuthGuard],
        data: { roles: ['Contabilidad'] }
      }, {
        path: 'arqueo-caja',
        loadChildren: () => import('./apparqueodecajas/arqueodecajas.module').then(m => m.ArqueodecajasModule),
        canActivate: [AuthGuard],
        data: { roles: ['Vendedor'] }
      }
      , {
        path: 'gastos',
        loadChildren: () => import('./appgastos/registros/registros-gastos.module').then(m => m.RegistrosGastosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Vendedor'] }
      }, {
        path: 'inventarios',
        loadChildren: () => import('./appinventarios/inventarios/inventarios.module').then(m => m.InventariosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Gerente', 'Vendedor'] }
      },{
        path: 'infomes-productos',
        loadChildren: () => import('./appinformes/infoproductos/infoproductos.module').then(m => m.InfoproductosModule),
        canActivate: [AuthGuard],
        data: { roles: ['Informes'] }
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    canActivate: [AuthLoginGuard],
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/inicio/404'
  }
];
