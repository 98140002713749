import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'; 
import { localStorageServices } from './localStorageServices';

@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router, private localdata:localStorageServices) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if(JSON.stringify(this.localdata.getValueFromLocalStorage())!='null' && JSON.stringify(this.localdata.getValueFromLocalStorage())!='{}' ){
            if (route.data.roles && route.data.roles.indexOf(this.localdata.getValueFromLocalStorage().grupo.group.name) === -1) {
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }

        this.router.navigate(['inicio/login']/*, { queryParams: { returnUrl: state.url }}*/);
        return false;
    }
}