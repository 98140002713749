import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ArqueodecajasRoutes } from './arqueodecajas.routing';
import { ArqueodecajasVistaComponent } from './vistas/arqueodecajavista.component';
import { ModalArqueodecajaModule } from './modal/modalarqueodecaja.module';
import { ArqueodecajasServices } from './arqueodecaja/arqueodecajas.services';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(ArqueodecajasRoutes),
		NgxDatatableModule,
		ModalArqueodecajaModule,
		NgSelectModule,
		NgbModule
	],
	declarations:[
		ArqueodecajasVistaComponent,
	],
	providers:[
		ArqueodecajasServices
	],
	exports:[
		ArqueodecajasVistaComponent
	]
})

export class ArqueodecajasModule {}
