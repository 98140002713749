import { Routes } from '@angular/router';
import { AuthGuard } from '../appauth/auth/auth.guard';
import { ArqueodecajasVistaComponent } from './vistas/arqueodecajavista.component';

export const ArqueodecajasRoutes: Routes = [{
	path: '',
	redirectTo: 'index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'index',
		component: ArqueodecajasVistaComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Arqueo de cajas',
			roles: ['Vendedor'],
			urls: [
			  { title: 'Home', url: '/home' },
			  { title: 'Página de inicio' }
			]
		},
	}]
}];
