import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Arqueodecajas } from '../../arqueodecaja/arqueodecajas.model';
import { ArqueodecajasServices } from '../../arqueodecaja/arqueodecajas.services';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';

@Component({
	selector: 'app-editarqueodecaja',
	templateUrl: './editarqueodecaja.component.html',
	styleUrls: ['./editarqueodecaja.component.scss']
})

export class EditArqueodecajaComponent implements OnInit {

	@Input() Arqueodecaja:Arqueodecajas;

	public formArqueodecajaMontoinicio:FormGroup;
	public editMontoinicio:boolean=false;

	public formArqueodecajaMontoentrega:FormGroup;
	public editMontoentrega:boolean=false;

	public formArqueodecajaCajerorecibe:FormGroup;
	public editCajerorecibe:boolean=false;

	public formArqueodecajaCajeroentrega:FormGroup;
	public editCajeroentrega:boolean=false;

	public formArqueodecajaCantidaventas:FormGroup;
	public editCantidaventas:boolean=false;

	public formArqueodecajaEstado:FormGroup;
	public editEstado:boolean=false;

	public formArqueodecajaFecha:FormGroup;
	public editFecha:boolean=false;

	public formArqueodecajaHora:FormGroup;
	public editHora:boolean=false;

	locales: any[]=[];
	userdata: any;
	comercial:any;

	constructor(private fb: FormBuilder,
		public localdata: localStorageServices,
		private activeModal:NgbActiveModal,
		private servicioArqueodecaja:ArqueodecajasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		console.log(this.Arqueodecaja);
		
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.comercial=this.userdata.empresa;
		let dataSend = { 'locale_id': this.comercial.id } as any;

		this.servicioArqueodecaja.viewUsuarioPorLocales(dataSend).subscribe((data: any) => {
			if (data.encargados) {
			  this.locales = data.encargados;
			}
		});

		this.formArqueodecajaMontoinicio=this.fb.group({
			montoinicio:[this.Arqueodecaja.montoinicio,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formArqueodecajaMontoentrega=this.fb.group({
			montoentrega:[this.Arqueodecaja.montoentrega,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formArqueodecajaCajerorecibe=this.fb.group({
			cajerorecibe:[this.Arqueodecaja.cajerorecibe,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formArqueodecajaCajeroentrega=this.fb.group({
			cajeroentrega:[this.Arqueodecaja.cajeroentrega,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formArqueodecajaCantidaventas=this.fb.group({
			cantidaventas:[this.Arqueodecaja.cantidaventas,Validators.compose([Validators.required,CustomValidators.number])],
		});

		var datePipeFecha = new DatePipe("en-US");
		this.formArqueodecajaFecha=this.fb.group({
			fecha:[datePipeFecha.transform(this.Arqueodecaja.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});

	}
	

	editArqueodecajaMontoinicioEnable(){
		this.editMontoinicio=true;
		this.formArqueodecajaMontoinicio=this.fb.group({
			montoinicio:[this.Arqueodecaja.montoinicio,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editArqueodecajaMontoinicioDisable(){
		this.editMontoinicio=false;
	}

	saveArqueodecajaMontoinicio(){
		let ArqueodecajaDataModel:Arqueodecajas=this.formArqueodecajaMontoinicio.value;
		ArqueodecajaDataModel.id=this.Arqueodecaja.id;
		this.Arqueodecaja.montoinicio=ArqueodecajaDataModel.montoinicio;
		this.servicioArqueodecaja.updateArqueodecajas(ArqueodecajaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMontoinicio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editArqueodecajaMontoentregaEnable(){
		this.editMontoentrega=true;
		this.formArqueodecajaMontoentrega=this.fb.group({
			montoentrega:[this.Arqueodecaja.montoentrega,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editArqueodecajaMontoentregaDisable(){
		this.editMontoentrega=false;
	}

	saveArqueodecajaMontoentrega(){
		let ArqueodecajaDataModel:Arqueodecajas=this.formArqueodecajaMontoentrega.value;
		ArqueodecajaDataModel.id=this.Arqueodecaja.id;
		this.Arqueodecaja.montoentrega=ArqueodecajaDataModel.montoentrega;
		this.servicioArqueodecaja.updateArqueodecajas(ArqueodecajaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMontoentrega=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editArqueodecajaCajerorecibeEnable(){
		this.editCajerorecibe=true;
		this.formArqueodecajaCajerorecibe=this.fb.group({
			cajerorecibe:[this.Arqueodecaja.cajerorecibe,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editArqueodecajaCajerorecibeDisable(){
		this.editCajerorecibe=false;
	}

	saveArqueodecajaCajerorecibe(){
		let ArqueodecajaDataModel:Arqueodecajas=this.formArqueodecajaCajerorecibe.value;
		ArqueodecajaDataModel.id=this.Arqueodecaja.id;
		this.Arqueodecaja.cajerorecibe=ArqueodecajaDataModel.cajerorecibe;
		this.servicioArqueodecaja.updateArqueodecajas(ArqueodecajaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCajerorecibe=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editArqueodecajaCajeroentregaEnable(){
		this.editCajeroentrega=true;
		this.formArqueodecajaCajeroentrega=this.fb.group({
			cajeroentrega:[this.Arqueodecaja.cajeroentrega,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editArqueodecajaCajeroentregaDisable(){
		this.editCajeroentrega=false;
	}

	saveArqueodecajaCajeroentrega(){
		let ArqueodecajaDataModel:Arqueodecajas=this.formArqueodecajaCajeroentrega.value;
		ArqueodecajaDataModel.id=this.Arqueodecaja.id;
		this.Arqueodecaja.cajeroentrega=ArqueodecajaDataModel.cajeroentrega;
		this.servicioArqueodecaja.updateArqueodecajas(ArqueodecajaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCajeroentrega=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editArqueodecajaCantidaventasEnable(){
		this.editCantidaventas=true;
		this.formArqueodecajaCantidaventas=this.fb.group({
			cantidaventas:[this.Arqueodecaja.cantidaventas,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editArqueodecajaCantidaventasDisable(){
		this.editCantidaventas=false;
	}

	saveArqueodecajaCantidaventas(){
		let ArqueodecajaDataModel:Arqueodecajas=this.formArqueodecajaCantidaventas.value;
		ArqueodecajaDataModel.id=this.Arqueodecaja.id;
		this.Arqueodecaja.cantidaventas=ArqueodecajaDataModel.cantidaventas;
		this.servicioArqueodecaja.updateArqueodecajas(ArqueodecajaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCantidaventas=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editArqueodecajaFechaEnable(){
		this.editFecha=true;
		var datePipeFecha = new DatePipe("en-US");
		this.formArqueodecajaFecha=this.fb.group({
			fecha:[datePipeFecha.transform(this.Arqueodecaja.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editArqueodecajaFechaDisable(){
		this.editFecha=false;
	}

	saveArqueodecajaFecha(){
		let ArqueodecajaDataModel:Arqueodecajas=this.formArqueodecajaFecha.value;
		ArqueodecajaDataModel.id=this.Arqueodecaja.id;
		this.Arqueodecaja.fecha=ArqueodecajaDataModel.fecha;
		this.servicioArqueodecaja.updateArqueodecajas(ArqueodecajaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


}

