import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditArqueodecajaComponent } from './edit/editarqueodecaja.component';
import { AddArqueodecajaComponent } from './add/addarqueodecaja.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule
	],
	declarations:[EditArqueodecajaComponent,AddArqueodecajaComponent],
	entryComponents:[EditArqueodecajaComponent],
	exports:[AddArqueodecajaComponent]
})
export class ModalArqueodecajaModule { }
