import { Injectable } from '@angular/core';
import { TokenParams } from '../../global/tokenParams';

@Injectable()
export class localStorageServices {

    public setAuthorizationData(Auth: TokenParams): void {
        localStorage.setItem('Authorization', JSON.stringify(Auth));
    }

    public getValueFromLocalStorage(): TokenParams {
        let datatoken = JSON.parse(localStorage.getItem('Authorization'));
        return datatoken == null ? null : datatoken;
    }

    public removeValueFromLocalStorage(): void {
        localStorage.setItem('Authorization', JSON.stringify({}));
    }

    public setVentaData(Auth: any): void {
        localStorage.setItem('venta', JSON.stringify(Auth));
    }

    public getValueVenta(): any {
        let datatoken = JSON.parse(localStorage.getItem('venta'));
        return datatoken == null ? null : datatoken;
    }

    public removeValueVenta(): void {
        localStorage.setItem('venta', JSON.stringify({}));
    }

    public removeValueCliente(): void {
        localStorage.setItem('cliente', JSON.stringify({}));
    }

    public setClienteData(Auth: any): void {
        localStorage.setItem('cliente', JSON.stringify(Auth));
    }

    public removeValueClienteCodeKuna(): void {
        localStorage.setItem('token', JSON.stringify({}));
    }

    public setClienteCodeKunaData(Auth: any): void {
        localStorage.setItem('token', JSON.stringify(Auth));
    }

    public getValueClienteCodeKuna(): any {
        let datatoken = JSON.parse(localStorage.getItem('token'));
        return datatoken == null ? null : datatoken;
    }

    public getValueCliente(): any {
        let datatoken = JSON.parse(localStorage.getItem('cliente'));
        return datatoken == null ? null : datatoken;
    }

    public verPermiso(codigo: string): any {
        let datatoken = JSON.parse(localStorage.getItem('Authorization'));
        return datatoken.grupo.permisocomprobantes.filter(permiso => permiso.comprobantelectronico.tipocomprobante.codigo === codigo);
    }

    public round(num: any, decimales = 2): number {
        let signo: any = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        let val: any = num[0] + 'e';
        return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
    }

    public round2Decimal(num: any, decimales = 2): number {
        let signo: any = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        let val: any = num[0] + 'e';
        return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
    }

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    groupByPromise(list, keyGetter) {

        let promise = new Promise((resolve, reject) => {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            resolve(map);
        });
        return promise;

    }

    public setPedidoDataToVenta(Auth: any): void {
        localStorage.setItem('pedidoventa', JSON.stringify(Auth));
    }

    public getPedidoDataToVenta(): any {
        let datatoken = JSON.parse(localStorage.getItem('pedidoventa'));
        return datatoken == null ? null : datatoken;
    }

    public removePedidoDataToVenta(): void {
        localStorage.setItem('pedidoventa', JSON.stringify({}));
    }
    
}