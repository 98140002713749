// config.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { log } from 'console';
import { Globals } from './global.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configUrl = '/config/config.json';

  constructor(private http: HttpClient) { }

  loadConfig() {
    return this.http.get<any>(this.configUrl).toPromise()
      .then(config => {
        Globals.BASE_URL_API_REST=config.apiUrl;
        Globals.API_CLI=config.frontEnd;
        console.log("Api URL:",Globals.BASE_URL_API_REST);
        console.log("FRONT URL:",Globals.API_CLI);
      }).catch(error => {
        console.error("Error al obtener la configuración:", error);
        Globals.BASE_URL_API_REST = 'http://localhost/asclepio/rest/'; // Valor predeterminado para la API REST
        Globals.API_CLI = 'localhost'; // Valor predeterminado para la aplicación cliente
      });
  }
}
