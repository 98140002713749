import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Arqueodecajas } from '../../arqueodecaja/arqueodecajas.model';
import { ArqueodecajasServices } from '../../arqueodecaja/arqueodecajas.services';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-addarqueodecaja',
	templateUrl: './addarqueodecaja.component.html',
	styleUrls: ['./addarqueodecaja.component.scss']
})

export class AddArqueodecajaComponent implements OnInit {
	public dataLocales:any;
	public formAddArqueodecaja: FormGroup;
	userdata: any;
	comercial:any
	fecha:any;
	locales: any[]=[];
	caja:any;
	constructor(private fb: FormBuilder,
		private servicioArqueodecaja:ArqueodecajasServices,
		public localdata: localStorageServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.comercial=this.userdata.empresa;
		let dataSend = { 'locale_id': this.comercial.id } as any;
		
		this.servicioArqueodecaja.viewUsuarioPorLocales(dataSend).subscribe((data: any) => {
			if (data.encargados) {
			  this.locales = data.encargados;
			}
		});
		
		this.vercajaregistro();
		
	}

	vercajaregistro(){
		const currentDate = new Date();
		const day = currentDate.getDate();
		const month = currentDate.getMonth() + 1;
		const year = currentDate.getFullYear();
		var datePipeFecha = new DatePipe("en-US");
		this.servicioArqueodecaja.viewDiarioCajas(this.comercial.id,this.userdata.datos.persona.id,datePipeFecha.transform(month + "/" + day + "/" + year , 'yyyy-MM-dd')).subscribe((data:any)=>{
			if(data.arqueodecaja && data.arqueodecaja.id!=undefined){
				this.caja=data.arqueodecaja;
				this.formAddArqueodecaja = this.fb.group({
					id:[data.arqueodecaja.id,Validators.compose([Validators.required,CustomValidators.number])],
					locale_id:[data.arqueodecaja.locale_id,Validators.compose([Validators.required,CustomValidators.number])],
					montoinicio:[data.arqueodecaja.montoinicio,Validators.compose([Validators.required,CustomValidators.number])],
					montoentrega:[data.arqueodecaja.montoentrega,Validators.compose([Validators.required,CustomValidators.number])],
					cajerorecibe:[data.arqueodecaja.cajerorecibe,Validators.compose([Validators.required,CustomValidators.number])],
					cajeroentrega:[data.arqueodecaja.cajeroentrega,Validators.compose([Validators.required,CustomValidators.number])],
					cantidaventas:[data.arqueodecaja.cantidaventas,Validators.compose([CustomValidators.number])],
					estado:[data.arqueodecaja.estado,null],
					fecha:[datePipeFecha.transform(data.arqueodecaja.fecha , 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
				});
			}else{
				this.caja=undefined;
				this.formAddArqueodecaja = this.fb.group({
					locale_id:[this.comercial.id,Validators.compose([Validators.required,CustomValidators.number])],
					montoinicio:[null,Validators.compose([Validators.required,CustomValidators.number])],
					montoentrega:[null,Validators.compose([CustomValidators.number])],
					cajerorecibe:[null,Validators.compose([CustomValidators.number])],
					cajeroentrega:[this.userdata.datos.persona.id,Validators.compose([Validators.required,CustomValidators.number])],
					cantidaventas:[1,Validators.compose([CustomValidators.number])],
					estado:[1,null],
					fecha:[datePipeFecha.transform(month + "/" + day + "/" + year , 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
				});
			}
		});
	}


	save(){
		let arqueodecajaDataModel:Arqueodecajas=this.formAddArqueodecaja.value;
		if(this.caja!=undefined){
			this.servicioArqueodecaja.updateArqueodecajas(arqueodecajaDataModel).subscribe((data:any)=>{
				if(data.estado){
					this.Noti.setNotification(data).then((datas)=>{
						if(datas){
							this.vercajaregistro();
						}
					});
				}else{
					this.Noti.setNotification(data);
				}
			});
		}else{
			this.servicioArqueodecaja.saveArqueodecajas(arqueodecajaDataModel).subscribe((data:any)=>{
				if(data.estado){
					this.Noti.setNotification(data).then((datas)=>{
						if(datas){
							this.vercajaregistro();
						}
					});
				}else{
					this.Noti.setNotification(data);
				}
			});
		}
	}

	entregar(){
		let arqueodecajaDataModel:Arqueodecajas=this.formAddArqueodecaja.value;
		arqueodecajaDataModel.estado=0;
		let data={'val':arqueodecajaDataModel.fecha,'msg':'Entregar dinero la cantidad de: '+arqueodecajaDataModel.montoentrega+' de la fecha de: '};
		this.Noti.msgConfir(data).then((datas)=>{
			this.servicioArqueodecaja.updateArqueodecajas(arqueodecajaDataModel).subscribe((data:any)=>{
				if(data.estado){
					this.Noti.setNotification(data).then((datas)=>{
						if(datas){
							this.vercajaregistro();
						}
					});
				}else{
					this.Noti.setNotification(data);
				}
			});
		});
	}
}