import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Globals, Impresora } from './global.model';


@Injectable()
export class ListaComercialesServices {

	constructor(private http: HttpClient) { }

	viewInformacionMetraje() {
		return this.http.get(Globals.BASE_URL_API_REST + 'lista_rollos_papel.json');
	}

	viewAdministradoresPorUsuario(data: any) {
		return this.http.put(Globals.BASE_URL_API_REST + 'opadministradores.json', data);
	}

	viewLocalesPorUsuario(data: any) {
		return this.http.post(Globals.BASE_URL_API_REST + 'locales_por_usarios.json', data);
	}

	viewContadoresPorUsuario(data: any) {
		return this.http.put(Globals.BASE_URL_API_REST + 'opcontabilidades.json', data);
	}

	// getDataEmpleadoConfig(data:any):Observable<any[]>{
	//     return this.http.post<any[]>(Globals.BASE_URL_API_REST+'opempleadoconfig.json',data);
	// }

	listasDeImpresoras() {
		return this.http.get(Globals.BASE_URL_API_REST + 'impresoras.json');
	}
	
	listasDePedidosVentas() {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_notificacion_pedido_venta.json');
	}

	listasDeFacturasVentas() {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_notificacion_factura_venta.json');
	}

	viewAdministradoresImpresoras(data: any) {
		return this.http.get(Globals.BASE_URL_API_REST + 'ap_printers_lista/' + data.group_id + '/' + data.user_id + '.json');
	}

	saveImpresoras(data: Impresora) {
		return this.http.post(Globals.BASE_URL_API_REST + 'printers.json', data);
	}

	updateImpresoras(data: Impresora) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'printers/' + data.id + '.json', data);
	}

	printerPagoVenta(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagos', data.data, { headers: headers });
	}

	printerPagoVentaAbono(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagoabonos', data.data, { headers: headers });
	}

	printerPueba(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/prueba', data.data, { headers: headers });
	}

	printerPagoCopiaVenta(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagocopia', data.data, { headers: headers });
	}

	printerMontoCierreCajaVenta(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/cierre', data.data, { headers: headers });
	}

	printerCodigoPrueba(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ' + 66630998755432);
		return this.http.post('https://' + data.ip + ':6663/codigo', data.data, { headers: headers });
	}

	viewDisenioDeudas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'op_deudas_disenoprendas/' + id + '.json');
	}

	viewConfigGeneral(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'config_general_config/' + id + '.json');
	}

	buscarSettinglocalesVentas(id: number) {
		return this.http.get(Globals.BASE_URL_API_REST + 'config_locale_info/' + id + '.json');
	}

	buscarCatastrosRuc(ruc: string) {
		let headers = new HttpHeaders().set('Authorization', 'SmartRoad ' + 1803994621);
		return this.http.get(Globals.BASE_URL_KAYA_CLIENTE_API_REST + 'op_kaya_personas_busquedas_ruc.json?nombres='+ruc, { headers: headers });
	}

	buscarCatastrosCedula(cedula: string) {
		let headers = new HttpHeaders().set('Authorization', 'SmartRoad ' + 1803994621);
		return this.http.get(Globals.BASE_URL_KAYA_CLIENTE_API_REST + 'op_kaya_personas_busquedas_cedula.json?nombres='+cedula, { headers: headers });
	}

	buscarValoresImpuesto(){
		return this.http.get(Globals.BASE_URL_API_REST + 'valorimpuestos.json');
	}

}

@Injectable()
export class ListaImpresorasServices {

	constructor(private http: HttpClient) { }

	viewAdministradoresImpresoras(data: any) {
		return this.http.get(Globals.BASE_URL_API_REST + 'ap_impresoras_lista/' + data.group_id + '/' + data.user_id + '.json');
	}

	saveImpresoras(data: Impresora) {
		return this.http.post(Globals.BASE_URL_API_REST + 'impresoras.json', data);
	}

	updateImpresoras(data: Impresora) {
		return this.http.patch(Globals.BASE_URL_API_REST + 'impresoras/' + data.id + '.json', data);
	}

	printerPagoCita(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagos', data.data, { headers: headers });
	}

	printerPagoCitaCuota(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/cuotas', data.data, { headers: headers });
	}

	printerPagoExamen(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/pagoexamenes', data.data, { headers: headers });
	}

	printerPagoExamenCuota(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/cuotaexamenes', data.data, { headers: headers });
	}

	printerPueba(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/prueba', data.data, { headers: headers });
	}

	printerDetalleExamenes(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/examenesdetalles', data.data, { headers: headers });
	}

	printerFactura(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/facturas', data.data, { headers: headers });
	}

	printerFacturaEat(data: any) {
		let headers = new HttpHeaders().set('Authorization', 'LocalPrinter ');
		return this.http.post('https://' + data.ip + ':3333/eat', data.data, { headers: headers });
	}

}