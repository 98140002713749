export class Users {
    id?: number;
    username?: string;
    passwordold?: string;
    password?: string;
    confirmPassword?: string;
    created?: Date;
    modified?: Date;
    estado?:number;
    identificador?:string;
    nicname?:string;
}