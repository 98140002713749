import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';
import { Users } from './users.model';
import { localStorageServices } from '../../auth/localStorageServices';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class UserServices {
    private formData: Users = new Users();

    constructor(private http: HttpClient, private localdata: localStorageServices) { }

    setDataUsuario(data: Users) {
        this.formData = data;
    }

    getDataUsuario() {
        var proveedor: Users = this.formData;
        let promise = new Promise((resolve, reject) => {
            resolve(proveedor);
        });
        return promise;
    }

    getDataUsers(): Observable<Users[]> {
        return this.http.get<Users[]>(Globals.BASE_URL_API_REST + 'users.json');
    }

    updatePassword(data: Users) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'editpass.json/' + this.localdata.getValueFromLocalStorage().datos.id, data);
    }

    update(data: Users) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'users/' + this.localdata.getValueFromLocalStorage().datos.id + '.json', data);
    }

    updatePhoto(data: any) {
        return this.http.post(Globals.BASE_URL_API_REST + 'fotousers.json', data);
    }

    saveUser(data: Users) {
        return this.http.post(Globals.BASE_URL_API_REST + 'users.json', data);
    }

    deleteUser(id: number) {
        return this.http.delete(Globals.BASE_URL_API_REST + 'users/' + id + '.json');
    }

    updateUser(data: Users) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'users/' + data.id + '.json', data);
    }

    selectOffUser(data: any) {
        return this.http.put(Globals.BASE_URL_API_REST + 'opencargados.json', data);
    }

    updateGroupUser(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'group_users/' + data.user_id + '.json', data);
    }

    saveGroupUser(data: any) {
        return this.http.post(Globals.BASE_URL_API_REST + 'group_users.json', data);
    }

    buscarGroupUser(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'op_grupo_users/' + id + '.json');
    }

    sesionesUser(data: any) {
        return this.http.put(Globals.BASE_URL_API_REST + 'lista_secciones_usuarios.json', data);
    }


    updateSessions(data: any) {
        return this.http.patch(Globals.BASE_URL_API_REST + 'sessions/' + data.id + '.json', data);
    }

    viewComercialesUser(id: number) {
        return this.http.get(Globals.BASE_URL_API_REST + 'comerciales/' + id + '.json');
    }


}
