import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';

@Injectable()
export class customValidatorMondogo {

  ageRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && (isNaN(control.value) || control.value < 1 || control.value > 500)) {
      return { 'ageRange': true };
    }
    return null;
  }

  numeric(control: AbstractControl) {
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };
    return null;
  }

  static digitoVerificador(validaciones: customValidatorMondogo): AsyncValidatorFn {
    return (control: AbstractControl): any => {
      let val = control.value;
      if (val) {
        var array = val.split("");
        if (array.length == 10) {
          if (validaciones.Cedula(val) == true) {
            return null;
          } else {
            return { 'invalidNumber': true };
          }
        }
      }
    };
  }

  static digitoVerificadorRuc(validaciones: customValidatorMondogo): AsyncValidatorFn {
    return (control: AbstractControl): any => {
      let val = control.value;
      if (val) {
        var array = val.split("");
        if (array.length == 13) {
          if (validaciones.validarRuc(val) == true) {
            return null;
          } else {
            return { 'invalRucNumber': true };
          }
        }
      }
    };
  }

  valorMaximo(maximo: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value) {
        if (maximo < c.value) {
          return { 'maximo': true };
        }
      }
      return null;
    }
  }

  Cedula(cedula: any) {
    if (cedula) {
      var array = cedula.split("");
      if (array.length == 10) {
        var total = 0;
        var digito = (array[9] * 1);
        for (var i = 0; i < 9; i++) {
          var mult = 0;
          if ((i % 2) != 0) {
            total = total + (array[i] * 1);
          } else {
            mult = array[i] * 2;
            if (mult > 9)
              total = total + (mult - 9);
            else
              total = total + mult;
          }
        }
        var decena = total / 10;
        decena = Math.floor(decena);
        decena = (decena + 1) * 10;
        var final = (decena - total);
        if ((final == 10 && digito == 0) || (final == digito))
          return true;
        else
          return false;
      }
    }
  }

  getEdad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--
    }
    return edad
  }

  checkLimit(enteros: number, decimal: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value) {
        let cadena = c.value.toString();
        if (cadena.indexOf('.') != -1) {
          let valor = cadena.split('.');
          if (c.value && (valor[0].length > enteros || valor[1].length > decimal)) {
            if (valor[0].length > enteros) {
              c.setValue(valor[0].substring(0, enteros) + '.' + valor[1]);
              return { 'entero': true };
            } else if (valor[1].length > decimal) {
              c.setValue(valor[0] + '.' + valor[1].substring(0, decimal));
              return { 'decimal': true };
            }

          }
        } else {
          if (c.value && cadena.length > enteros) {
            c.setValue(cadena.substring(0, enteros));
            return { 'entero': true };
          }
        }
      }

      return null;
    };
  }


  validarRuc(ruc: string): boolean {
    const noTieneTreceDigitos = ruc.length !== 13;
    if (noTieneTreceDigitos) {
      return false;
    }
    const noTieneCeroCeroUnoAlFinal = !(ruc.substring(10) === '001');
    if (noTieneCeroCeroUnoAlFinal) {
      return false;
    }

    return this.validarDigitoVerificador(ruc);

  }

  validarDigitoVerificador(ruc: string): boolean {
    const diezDigitosIniciales = ruc.substring(0, 10);
    const tercerDigito = Number(ruc.substring(2, 3));
    switch (tercerDigito) {
      case 6:
        return this.validarTercerDigitoSeis(diezDigitosIniciales);
      case 9:
        return this.validaTercerDigitoNueve(diezDigitosIniciales);
      default:
        return this.Cedula(diezDigitosIniciales);
    }
  }

  validarTercerDigitoSeis(diezDigitosIniciales: string) {
    const digitoUno = Number(diezDigitosIniciales.substring(0, 1));
    const digitoUnoMultiplicado = digitoUno * 3;
    const digitoDos = Number(diezDigitosIniciales.substring(1, 2));
    const digitoDosMultiplicado = digitoDos * 2;
    const digitoTres = Number(diezDigitosIniciales.substring(2, 3));
    const digitoTresMultiplicado = digitoTres * 7;
    const digitoCuatro = Number(diezDigitosIniciales.substring(3, 4));
    const digitoCuatroMultiplicado = digitoCuatro * 6;
    const digitoCinco = Number(diezDigitosIniciales.substring(4, 5));
    const digitoCincoMultiplicado = digitoCinco * 5;
    const digitoSeis = Number(diezDigitosIniciales.substring(5, 6));
    const digitoSeisMultiplicado = digitoSeis * 4;
    const digitoSiete = Number(diezDigitosIniciales.substring(6, 7));
    const digitoSieteMultiplicado = digitoSiete * 3;
    const digitoOcho = Number(diezDigitosIniciales.substring(7, 8));
    const digitoOchoMultiplicado = digitoOcho * 2;
    const digitoNueve = Number(diezDigitosIniciales.substring(8, 9));


    const digitoUnoMultiplicadoYSumado = this.sumaDigito(digitoUnoMultiplicado);
    const digitoDosMultiplicadoYSumado = this.sumaDigito(digitoDosMultiplicado);
    const digitoTresMultiplicadoYSumado = this.sumaDigito(digitoTresMultiplicado);
    const digitoCuatroMultiplicadoYSumado = this.sumaDigito(digitoCuatroMultiplicado);
    const digitoCincoMultiplicadoYSumado = this.sumaDigito(digitoCincoMultiplicado);
    const digitoSeisMultiplicadoYSumado = this.sumaDigito(digitoSeisMultiplicado);
    const digitoSieteMultiplicadoYSumado = this.sumaDigito(digitoSieteMultiplicado);
    const digitoOchoMultiplicadoYSumado = this.sumaDigito(digitoOchoMultiplicado);
    const sumaDePersonasNaturales = digitoUnoMultiplicadoYSumado + digitoDosMultiplicadoYSumado + digitoTresMultiplicadoYSumado
      + digitoCuatroMultiplicadoYSumado + digitoCincoMultiplicadoYSumado + digitoSeisMultiplicadoYSumado
      + digitoSieteMultiplicadoYSumado + digitoOchoMultiplicadoYSumado;
    const sumaDeSociedades = digitoUno + digitoDos + digitoTres + digitoCuatro + digitoCinco + digitoSeis + digitoSiete + digitoOcho;

    let verificadorDePersonasNaturales = 11 - (sumaDePersonasNaturales % 11);
    const verificadorDeSociedades = 11 - (sumaDeSociedades % 11);
    const verificadoresIgualAOnce = verificadorDePersonasNaturales === 11 || verificadorDeSociedades === 11
    if (verificadoresIgualAOnce) {
      verificadorDePersonasNaturales = 0;
    }
    if (verificadorDePersonasNaturales === digitoNueve || verificadorDeSociedades === digitoNueve) {
      return true;
    } else {
      return this.Cedula(diezDigitosIniciales);
    }
  }

  validaTercerDigitoNueve(diezDigitosIniciales: string): boolean {
    const digitoUno = Number(diezDigitosIniciales.substring(0, 1));
    const digitoUnoMultiplicado = digitoUno * 4;
    const digitoDos = Number(diezDigitosIniciales.substring(1, 2));
    const digitoDosMultiplicado = digitoDos * 3;
    const digitoTres = Number(diezDigitosIniciales.substring(2, 3));
    const digitoTresMultiplicado = digitoTres * 2;
    const digitoCuatro = Number(diezDigitosIniciales.substring(3, 4));
    const digitoCuatroMultiplicado = digitoCuatro * 7;
    const digitoCinco = Number(diezDigitosIniciales.substring(4, 5));
    const digitoCincoMultiplicado = digitoCinco * 6;
    const digitoSeis = Number(diezDigitosIniciales.substring(5, 6));
    const digitoSeisMultiplicado = digitoSeis * 5;
    const digitoSiete = Number(diezDigitosIniciales.substring(6, 7));
    const digitoSieteMultiplicado = digitoSiete * 4;
    const digitoOcho = Number(diezDigitosIniciales.substring(7, 8));
    const digitoOchoMultiplicado = digitoOcho * 3;
    const digitoNueve = Number(diezDigitosIniciales.substring(8, 9));
    const digitoNueveMultiplicado = digitoNueve * 2;
    const digitoDiez = Number(diezDigitosIniciales.substring(9));

    const sumaDigitosMultiplicados = digitoUnoMultiplicado + digitoDosMultiplicado + digitoTresMultiplicado
      + digitoCuatroMultiplicado + digitoCincoMultiplicado + digitoSeisMultiplicado + digitoSieteMultiplicado
      + digitoOchoMultiplicado + digitoNueveMultiplicado;

    let verificador = 11 - (sumaDigitosMultiplicados % 11);
    if (verificador === 11) {
      verificador = 0;
    }

    if (verificador === 10) {
      return false;
    } else if (verificador === digitoDiez) {
      return true;
    }
    return false;
  }

  sumaDigito(digito: number) {
    let sumaDigitos = digito;
    let valorUno = 0;
    let valorDos = 0;
    if (digito > 9) {
      valorUno = Number(digito.toString().substring(0, 1));
      valorDos = Number(digito.toString().substring(1, 2));
      sumaDigitos = valorUno + valorDos;
      if (sumaDigitos > 9) {
        sumaDigitos = this.sumaDigito(sumaDigitos);
      }
    }
    return sumaDigitos;
  }

}